import runtimes from '@/assets/runtimes.json'
export default {

  data: () => ({
    items: runtimes,
  }),

  methods: {
    redirectToCreateNewForm(lang) {
      var tmplang = lang.replace(/\./g, "").toLowerCase();
      this.$router.push({ name: 'Create new function form', params: { langCode: tmplang } })
    },

  }
}