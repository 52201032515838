<template>
  <v-container>
    <core-toolbar-name
      :page-name="this.$tc('message.pages.title.create_function_language', 1)"
    ></core-toolbar-name>

    <v-item-group>
      <v-row>
        <v-col v-for="(item, i) in items" :key="i" cols="12" md="6" xl="4">
          <v-item v-slot="{ active }">
            <v-card
              :color="active ? 'primary' : item.color"
              dark
              height="280 "
              @click="redirectToCreateNewForm(item.name)"
              class="d-flex align-stretch"
              scrollable
            >
              <div
                class="d-flex flex-no-wrap justify-space-between align-stretch"
              >
                <div class="white">
                  <v-avatar class="ma-3" tile min-width="96" min-height="226">
                    <v-img
                      :src="require('@/assets/lang-icons/' + item.logo)"
                      contain
                      min-height="226"
                    >
                      <v-icon v-if="active" color="green" large>
                        mdi-check
                      </v-icon>
                    </v-img>
                  </v-avatar>
                </div>
                <div>
                  <v-card-title
                    class="headline"
                    v-text="item.name"
                  ></v-card-title>
                  <v-card-subtitle v-text="item.description"> </v-card-subtitle>
                </div>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
  </v-container>
</template>

<script>
import functionChooseLangMixin from "@/mixins/views/pages/FunctionsCreateLanguage.js";
import CoreToolbarName from "@/components/core/ToolbarName.vue";

export default {
  name: "FunctionCreateLanguage",
  components: {
    CoreToolbarName,
  },
  mixins: [functionChooseLangMixin],
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
};
</script>

<style></style>
